import { message, Upload } from "antd";
import { InboxOutlined } from "@ant-design/icons";

import { deleteAttachmentsAPI, uploadFileAPI } from "../services/upload";

const { Dragger } = Upload;

export default function FileUpload({ formats, updateState, data = [] }) {
  const documentFileTypes = [
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    "application/pdf",
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    "text/csv",
    "image/jpeg",
    "image/png",
  ];

  const customUploadRequest = async (options) => {
    const { onSuccess, onError, file } = options;

    try {
      const formData = new FormData();
      formData.append("file", file);
      const res = await uploadFileAPI(formData);
      updateState((prev) => [
        ...prev,
        {
          file: res.data.file_path,
          actualFileName: file.name,
        },
      ]);
      onSuccess("File uploaded successfully", file.name);
    } catch (err) {
      console.log(err);
      onError(err);
    }
  };

  const uploadProps = {
    name: "file",
    defaultFileList: data,
    beforeUpload(file) {
      if (!documentFileTypes.includes(file.type)) {
        message.info("Cannot upload this file");
        return Upload.LIST_IGNORE;
      }
      return true;
    },
    onChange(info, fileList) {
      if (info.file.status === "done") {
        message.success(`${info.file.name} file uploaded successfully`);
      } else if (info.file.status === "error") {
        message.error(`${info.file.name} file upload failed.`);
      }
    },
    async onRemove(file) {
      let curFile = {};

      updateState((prev) => {
        return prev.filter((item) => {
          if (item.actualFileName === file.name) {
            curFile = item;
            return false;
          }
          return true;
        });
      });
      try {
        await deleteAttachmentsAPI({ name: curFile.file });
      } catch (err) {
        console.log(err);
      }
      return true;
    },
    customRequest: customUploadRequest,
  };

  return (
    <Dragger
      accept={formats}
      id="upload-file"
      {...uploadProps}
      listType="picture"
    >
      <div>
        <div
          style={{
            margin: "0 auto",
            padding: "5px",
            display: "flex",
            alignItems: "center",
            maxWidth: "max-content",
            borderRadius: "8px",
            border: "1px solid #EAECF0",
            background: "#FFF",
            boxShadow: "0px 1px 2px 0px rgba(16, 24, 40, 0.05)",
          }}
        >
          <InboxOutlined />
        </div>
        <p className="ant-upload-text">
          <span>Click to Upload </span>
          or drag and drop
        </p>
      </div>
    </Dragger>
  );
}
