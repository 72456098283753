import React from "react";
import "./PovertySection.css";
import group39217 from "../../assets/img/group-39217.png";
import group39216 from "../../assets/img/group-39216.png";
import { Col, Row } from "antd";
import { OverPack } from "rc-scroll-anim";
import TweenOne from "rc-tween-one";
import QueueAnim from "rc-queue-anim";

const PovertySection = () => {
  return (
    <div className="poverty-section">
      {/* <div className="row first-row"> */}
      <Row gutter={[16, 16]}>
        <Col xs={24} lg={15}>
          <div className="group-12">
            <p className="div-2">
              <span className="orange-title">
                Understanding Poverty
                <br />
              </span>
              <span
                style={{
                  fontSize: "44px",
                  fontWeight: "700",
                  fontFamily: "'Open Sans', sans-serif",
                }}
              >
                in Kuppam
              </span>
            </p>
            <p className="the-multidimensional">
              “The Multidimensional Poverty Index (MPI) measures various
              deprivations in education, health, and living standards, offering
              a comprehensive perspective beyond traditional income-based
              metrics. Kuppam aims to address these deprivations through
              targeted initiatives and sustainable policies.”
            </p>
          </div>
        </Col>
        <Col xs={24} lg={9}>
          <div className="info-column">
          <OverPack style={{ overflow: "hidden", height: "auto" }}>
        <TweenOne
          key="0"
          animation={{ opacity: 1 }}
          className="code-box-shape"
          style={{ opacity: 0, marginBottom: 10 }}
        />
        <QueueAnim
          key="queue"
          leaveReverse
          style={{
           
            justifyContent: "space-between", // Use flexbox for layout
          }}
        >
         <div key="a" className="group-37">
              <div className="overlap-group-8">
                <p className="andhra-pradesh-s-MPI">
                  <span className="text-wrapper-15">
                    Andhra Pradesh’s MPI Ranking:{" "}
                  </span>
                  <br />
                  <span className="text-wrapper-17">
                    20th in India with a poverty 
                    rate of 6.06%
                  </span>
                </p>
              </div>
            </div>

            <div  key ="b" className="group-39">
              <div className="overlap-group-8">
                <p className="significant">
                  <span className="text-wrapper-15">
                    Significant reduction in poverty:{" "}
                  </span>
                  <br />
                  <span className="text-wrapper-17">
                    From 11.77% in 2015-16 to 6.06% in 2019-21
                  </span>
                </p>
              </div>
            </div> 
        </QueueAnim>
      </OverPack>
            

            
          </div>
        </Col>
      </Row>
      {/* </div> */}

      {/* <div className="row second-row"> */}
      <Row gutter={[12, 12]}>
        <Col
          xs={24}
          lg={12}
          style={{ display: "flex", justifyContent: "center" }}
        >
          <img
            className="group-40"
            alt="Group"
            style={{ widh: "100%" }}
            src="https://give.do/blog/wp-content/uploads/2023/08/Best-NGOs-in-Andhra-Pradesh-fighting-poverty.jpg"
          />
        </Col>
        <Col
          xs={24}
          lg={12}
          style={{ display: "flex", justifyContent: "center" }}
        >
          <img className="group-36" alt="Group" src="https://www.aisa.in/wp-content/uploads/2021/12/04/niti-aayog-data-bursts-development-bubble/fx1_lrg.jpg" />
        </Col>
      </Row>
      {/* </div> */}
    </div>
  );
};

export default PovertySection;
